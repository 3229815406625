import Splide from '@splidejs/splide';
export default class TopSlideImage {
    static exec() {
        const els = document.querySelectorAll('[data-component="top-slide-image"]');
        els.forEach(function (el) {
            new TopSlideImage(el);
        });
    }

    el;
    constructor(element) {
        const self = this;
        this.el = element;
        const splide_el = element.querySelector('[data-el="splide"]');
        this.splide = new Splide(splide_el, {
            type: 'loop',
            speed: 1000,
            arrows: false,
            autoplay: true,
            pagination: false,
            autoWidth: true
        }).mount();
    }
}