import Splide from '@splidejs/splide';
export default class TopHero {
    static exec() {
        const els = document.querySelectorAll('[data-component="top-hero"]');
        els.forEach(function (el) {
            new TopHero(el);
        });
    }

    el;
    constructor(element) {
        const self = this;
        this.el = element;
        const splide_el = element.querySelector('[data-el="splide"]');
        this.splide = new Splide(splide_el, {
            type: 'fade',
            speed: 1000,
            arrows: false,
            autoplay: true,
            pagination: false
        }).mount();

        const fade_in_el = element.querySelectorAll('[data-el="transition-fade-in"]');
        if (fade_in_el) {
            fade_in_el.forEach(function(el) {
                el.dataset.state = 'active';
            });
        }
    }
}